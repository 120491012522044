@import "../../theme/colors";

.ads {
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: center;
  margin-bottom: 60px;

  img {
    width: 100%;
    max-width: 400px;
    height: auto;
    border: 1px solid #000;
  }
}

.labelAd {
  text-align: center;
  margin-bottom: 0;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}