@import "../../theme/colors";

.cardWrapper {
  margin-bottom: 80px;
  display: flex;

  .card {
    padding: 20px 25px;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    background-color: $primaryLight;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  .currentAbo {
    padding: 10px 15px;
    background: white;
    color: $primary;
  }

  .upgradeBtn {
    padding: 10px 15px;
    background: white;
    color: $text;
  }

  .card-title {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .content {
    margin-bottom: 10px;
  }

  .card.is-active {
    background-color: $primary;
    color: $white;

    .upgradeBtn {
      color: $text;
    }
  }

  ul {
    padding-left: 15px;
    list-style: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    i {
      margin-right: 10px;
    }

    li {
      margin-bottom: 5px;
    }
  }


}